<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-if="clusterId"
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Bütçe Bilgileri"
            icon="mdi-script"
            :add-route="
              can('edit-budget') ? { name: 'budget.definition.create' } : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-budget')"
            @click:edit="handleEditClick"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            :delete-enabled="selectedItems.length === 1"
            @click:delete="handleDeleteClick"
            :show-delete="can('delete-budget')"
          >
            <template slot="buttons:append">
              <v-btn
                class="float-end ms-2"
                color="pink"
                outlined
                small
                @click="handleCloneClick"
                :disabled="
                  selectedItems.length === 0 || selectedItems.length > 1
                "
              >
                Kopyala
              </v-btn>
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'budget.definition.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ item }">
          <rs-table-cell-boolean :value="item.is_active" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.starts_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.ends_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.monthly_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actual_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.actual_monthly_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-enable -->
      </v-data-table>
      <CloneBudgetForm ref="cloneBudgetForm" @saved="loadList" />
      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDelete"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import CloneBudgetForm from "../components/CloneBudgetForm.vue";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  components: {
    CloneBudgetForm,
  },
  data() {
    return {
      options: {
        sortBy: ["name"],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-budget"),
        },
        {
          text: "Adı",
          value: "name",
          searchable: "text",
          width: "300px",
          align: "left",
        },
        {
          text: "Borçlandırma Türü",
          value: "income_type_name",
          searchable: "text",
          width: "75px",
          align: "left",
        },
        {
          text: "Başlangıç Tarihi",
          value: "starts_on",
          searchable: "date",
          align: "left",
        },
        {
          text: "Bitiş Tarihi",
          value: "ends_on",
          searchable: "date",
          align: "left",
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          align: "center",
          width: "80px",
        },
        {
          text: "Ay",
          value: "month_count",
          searchable: "number",
          align: "left",
        },
        {
          text: "Toplam Tutar",
          value: "total_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Aylık Tutar",
          value: "monthly_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gerçekleşen Tutar",
          value: "actual_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gerçekleşen Aylık",
          value: "actual_monthly_amount",
          searchable: "number",
          align: "end",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => `budget/${this.clusterId}`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query(`budgets`, { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "budget.definition.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
    handleCloneClick() {
      if (this.selectedItems.length !== 1 || this.isLoading) {
        return false;
      }

      this.$refs.cloneBudgetForm.show(this.selectedItems[0]);
    },
    handleDelete() {
      this.isLoading = true;

      this.$api
        .delete(`budgets/${this.selectedItems[0].id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems[0].name} bütçeyi silmek istediğinizden emin misiniz?`
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
