<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Bütçe Kopyalama</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-text-field
              label="Adı"
              :rules="[rules.required, rules.maxLength(formData.name, 100)]"
              v-model="formData.name"
            />
          </v-col>
          <v-col sm="12">
            <rs-datepicker
              label="Başlangıç Tarihi"
              v-model="formData.starts_on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col sm="12">
            <rs-datepicker
              label="Biitş Tarihi"
              v-model="formData.ends_on"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col sm="12">
            <rs-text-field
              label="Artış Oranı"
              :rules="[
                rules.required,
                rules.max(formData.increase_rate, 100),
                rules.min(formData.increase_rate, 0),
              ]"
              v-model="formData.increase_rate"
              prefix="%"
              type="number"
              step="1"
              min="0"
              max="100"
              placeholder="50"
              hint="Tüm tutarlar bu yüzdelik oranda arttırılır."
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          :hide-save-close="disabled"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import hasForm from "@/view/mixins/hasForm";

export default {
  mixins: [hasForm],
  computed: {
    ...mapGetters(["cluster"]),
  },
  data() {
    return {
      item: null,
      formData: {
        name: null,
        starts_on: null,
        ends_on: null,
        increase_rate: null,
      },
    };
  },
  methods: {
    show(item) {
      this.item = null;
      this.item = item;
      const currentYear = new Date().getFullYear();
      this.formData = Object.assign({
        name: null,
        starts_on: new Date(currentYear + 1, 0, 2).toJSON().split("T")[0],
        ends_on: new Date(currentYear + 1, 11, 32).toJSON().split("T")[0],
        increase_rate: null,
      });

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      this.$api
        .post(`budgets/${this.item.id}/clone`, formData)
        .then(() => {
          this.$toast.success("Kaydedildi");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
